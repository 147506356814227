<template>
  <div>
    <div class="my-3">
      <div class="d-flex align-center justify-end">
        <v-checkbox
          readonly
          class="my-0 py-0"
          v-model="showPrice"
          color="cyan white--text"
          label="Show Price To Engineer"
        />
        <div
          class="d-flex align-center ml-4 pl-4 custom-border-left"
          v-if="false"
        >
          <div class="mr-2">
            <label for="discount-level" class="mb-0 font-weight-600"
              >Discount Level</label
            >
          </div>
          <div>
            <v-radio-group
              readonly
              id="discount-level"
              v-model="discountLevel"
              row
              hide-details
              class="px-0 py-0"
            >
              <v-radio
                label="Line Item"
                value="line_item"
                color="cyan"
              ></v-radio>
              <v-radio
                label="Transaction"
                value="transaction"
                color="cyan"
              ></v-radio>
            </v-radio-group>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex grey lighten-4 px-2">
      <div
        class="color-custom-blue py-2 font-weight-700 text-center"
        style="width: 5%"
      >
        #
      </div>
      <div
        class="color-custom-blue py-2 font-weight-700 text-left"
        :style="get_col_style('product')"
      >
        Product/Service/Equipment
      </div>
      <div
        class="color-custom-blue py-2 font-weight-700 text-center"
        style="width: 10%"
      >
        Selling/Unit Cost
      </div>
      <div
        class="color-custom-blue py-2 font-weight-700 text-center"
        style="width: 10%"
      >
        Quantity
      </div>
      <div
        v-if="discountLevel == 'line_item'"
        class="color-custom-blue py-2 font-weight-700 text-center"
        style="width: 10%"
      >
        Discount
      </div>
      <div
        class="color-custom-blue py-2 font-weight-700 text-center"
        style="width: 10%"
      >
        Total
      </div>
    </div>
    <div
      v-for="(row, index) in db_line_items"
      class="line-item-drag"
      :key="`line-item-drag-${index}`"
      :class="row.class"
    >
      <div
        :id="`line-item-drag-${index}`"
        class="d-flex py-2 px-2"
        :class="{ 'item-is-child': row.is_child }"
      >
        <div
          class="font-weight-600 text-center"
          style="width: 5%"
          v-if="row.product_type != 'header'"
        >
          <span v-if="!row.is_child"
            ><v-chip>{{ row.sno }}.</v-chip></span
          >
        </div>
        <div
          v-if="row.is_child"
          class="font-weight-600 text-center"
          style="width: 5%"
        >
          <v-chip>{{ row.sno }}.</v-chip>
        </div>
        <div v-if="row.type == 'header'" class="text-left" style="width: 100%">
          <v-text-field
            v-model="row.product"
            class="line-item-header-text px-0"
            placeholder="Header"
            dense
            filled
            solo
            readonly
            flat
            color="cyan"
          >
            <template v-slot:append> </template>
          </v-text-field>
        </div>
        <div
          v-else
          class="text-left"
          :style="get_col_style('product', row.is_child)"
        >
          <div class="d-flex">
            <v-avatar size="55">
              <img
                v-if="row?.image?.url"
                :src="row?.image?.url"
                :alt="row?.image?.name"
              />
              <img v-else :src="$defaultImage" />
            </v-avatar>
            <div style="width: 100%" class="ml-4">
              <v-expansion-panels class="cepfli">
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <v-text-field
                      readonly
                      v-model="row.product"
                      class="line-item-header-text px-0"
                      placeholder="Product/Service"
                      dense
                      filled
                      solo
                      flat
                      color="cyan"
                    >
                      <template v-slot:append>
                        <v-chip
                          color="cyan white--text"
                          label
                          class="lip-type text-uppercase font-weight-600"
                          :class="{
                            product: row.product_type == 'goods',
                            service: row.product_type == 'service',
                            equipment: row.product_type == 'equipment',
                            other: row.product_type == 'other',
                          }"
                        >
                          {{ row.type }}
                        </v-chip>
                      </template>
                    </v-text-field>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-textarea
                      readonly
                      class="px-0"
                      v-model="row.description"
                      placeholder="Description"
                      dense
                      filled
                      solo
                      flat
                      rows="2"
                      auto-grow
                      color="cyan"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-checkbox
                v-if="row.type == 'product' && row.is_equipment == 1"
                inset
                color="cyan"
                label="As Equipment"
                dense
                disabled
                class="pt-0 mt-2"
                v-model="row.is_equipment"
                hide-details
                :false-value="0"
                :true-value="1"
              >
              </v-checkbox>
              <div
                v-if="row.has_property"
                class="font-weight-600 d-flex align-center mt-2"
              >
                <template
                  v-if="row.product_type == 'equipment' && row && row.serial_no"
                >
                  <v-chip
                    label
                    color="chip-custom-blue"
                    outlined
                    class="text-white p-3 mr-2"
                    style="font-weight: 700; margin-top: -6px"
                    >{{ row.serial_no }}</v-chip
                  >
                </template>
                <template v-if="row?.property?.parent?.name">
                  <v-chip>{{ row.property.parent.name }}</v-chip>
                  <v-icon class="px-1">mdi-chevron-right</v-icon>
                </template>
                <template v-if="row?.property?.child?.name">
                  <v-chip>{{ row.property.child.name }}</v-chip>
                  <v-icon class="px-1">mdi-chevron-right</v-icon>
                </template>
                <template v-if="row?.property?.type == 'child-child-'">
                  <v-chip style="padding: 20px !important">
                    <div>
                      <div>
                        {{ row?.property?.property_name }}
                      </div>
                      <div>{{ row.property.name }}</div>
                    </div>
                  </v-chip>
                </template>
                <template v-if="row?.property?.type == 'child-'">
                  <v-chip style="padding: 20px !important">
                    <div>
                      <div>
                        {{ row?.property?.property_name }}
                      </div>
                      <div>{{ row.property.name }}</div>
                    </div>
                  </v-chip>
                </template>
                <template v-if="row?.property?.type == 'parent-'">
                  <v-chip style="padding: 20px !important">
                    <div>
                      <div>
                        {{ row?.property?.property_name }}
                      </div>
                      <div>{{ row.property.name }}</div>
                    </div>
                  </v-chip>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="!(row.type == 'header')"
          class="text-center"
          style="width: 10%"
        >
          <v-text-field
            readonly
            class="line-item-header-text px-0"
            placeholder="Selling/Unit Cost"
            dense
            filled
            solo
            flat
            type="number"
            v-model="row.rate"
            color="cyan"
          />
        </div>
        <div
          v-if="!(row.type == 'header')"
          class="text-center"
          style="width: 10%"
        >
          <v-text-field
            readonly
            class="line-item-header-text px-0"
            placeholder="Quantity"
            dense
            filled
            solo
            flat
            type="number"
            v-model="row.quantity"
            :prepend="row.uom"
            color="cyan"
          />
        </div>
        <div
          v-if="discountLevel == 'line_item' && !(row.type == 'header')"
          class="text-center"
          style="width: 10%"
        >
          <div class="d-flex">
            <v-text-field
              readonly
              hide-details
              class="line-item-header-text px-0"
              placeholder="Discount"
              dense
              filled
              solo
              flat
              type="number"
              v-model="row.discount_value"
              color="cyan"
              style="width: 50%; min-width: unset"
            />
            <v-select
              readonly
              :items="discount_type_list"
              v-model="row.discount_type"
              hide-details
              item-text="type"
              item-value="value"
              item-color="cyan"
              class="line-item-header-text discount-type text-h6 px-0"
              placeholder="Type"
              dense
              filled
              solo
              flat
              color="cyan"
              style="width: 50%"
            />
          </div>
        </div>
        <div
          v-if="!(row.type == 'header')"
          class="text-center"
          style="width: 10%"
        >
          <v-text-field
            readonly
            class="line-item-header-text px-0"
            placeholder="Total"
            dense
            filled
            solo
            flat
            type="number"
            v-model="row.total"
            color="cyan"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GET } from "@/core/services/store/request.module";
export default {
  name: "visit-line-item",
  props: {
    showPrice: {
      type: Boolean,
      default: false,
    },
    discountLevel: {
      type: String,
      default: "transaction",
    },
    ticketId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      db_line_items: [],
      loading: false,
    };
  },
  methods: {
    get_line_items() {
      this.loading = true;
      this.$store
        .dispatch(GET, { url: `visit-v1/${this.ticketId}/line-items` })
        .then(({ data }) => {
          this.db_line_items = data;
        })
        .catch((error) => {
          console.log({ error });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    get_col_style(param, child) {
      let width = 55;
      if (param == "product") {
        if (!this.showPrice) {
          width = 85;
        } else if (this.showPrice) {
          width = 55;
          if (this.discountLevel == "transaction") {
            width = 85;
          }
        }
      }
      return { width: child ? `${width - 5}%` : `${width}%` };
    },
    /*  get_col_style(param, child) {
      let width = 46;
      if (param == "product") {
        if (!this.show_price) {
          width = 70;
        } else if (this.show_price) {
          width = 46;
          if (this.discount_level == "transaction") {
            width = 70;
          }
        }
      }
      return { width: child ? `${width - 5}%` : `${width}%` };
    }, */
  },
  mounted() {
    this.get_line_items();
  },
  computed: {},
};
</script>
